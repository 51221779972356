<template>
    <!-- PRODUCT SELECTION MODAL -->
    <transition name="fade">
        <div
            class="c-modal-product c-modal-product--open"
            v-if="props.assembling.open"
            :class="{
                'e-modal--close-modal': !props.assembling.open,
            }"
        >
            <div class="c-modal-product__outer-wrap">
                <div class="c-modal-product__main">
                    <!--
                    <div id="modal-item-added">
                        <div
                            class="c-modal-product__content-wrap c-modal-product__content-wrap--single-message"
                        >
                            <div
                                class="c-modal-product__close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M11.833 10l7.787-7.788A1.294 1.294 0 1017.788.38L10 8.168 2.212.38A1.294 1.294 0 10.38 2.212L8.168 10 .38 17.788A1.294 1.294 0 001.296 20c.332 0 .664-.127.916-.38L10 11.832l7.788 7.788a1.292 1.292 0 001.832 0 1.294 1.294 0 000-1.832L11.833 10z"
                                    />
                                </svg>
                            </div>

                            <div class="c-modal-product__content">
                                <p class="c-modal-product__content-title">
                                    {{ success }}<br />
                                    is aan uw winkelwagen toegevoegd.
                                </p>
                                <div class="c-modal-product__buttons">
                                    <a
                                        href="#"
                                        @click="closeModal()"
                                        class="e-button e-button--grey-light"
                                        >Verder winkelen</a
                                    >
                                    <a href="/winkelwagen" class="e-button"
                                        >Naar winkelwagen</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>-->

                    <div>
                        <div class="c-modal-product__content-wrap">
                            <div
                                class="c-modal-product__close"
                                @click="closeModal()"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M11.833 10l7.787-7.788A1.294 1.294 0 1017.788.38L10 8.168 2.212.38A1.294 1.294 0 10.38 2.212L8.168 10 .38 17.788A1.294 1.294 0 001.296 20c.332 0 .664-.127.916-.38L10 11.832l7.788 7.788a1.292 1.292 0 001.832 0 1.294 1.294 0 000-1.832L11.833 10z"
                                    />
                                </svg>
                            </div>

                            <div class="c-modal-product__title">
                                <h2>
                                    <span>Samenstellen</span> Online Keuzecadeau
                                </h2>
                            </div>

                            <div class="c-modal-product__inner-wrap">
                                <div
                                    class="c-modal-product__overview c-modal-product__overview--two-col"
                                >
                                    <div
                                        class="c-modal-product__input c-modal-product__input--fw"
                                    >
                                        <div
                                            class="c-modal-product__input-heading"
                                        >
                                            <label
                                                class="c-modal-product__label c-modal-product__input-label e-form__label"
                                            >
                                                <span>1.</span>
                                                <span
                                                    v-if="
                                                        props.assembling
                                                            .canChangeAmount
                                                    "
                                                    >Kies het aantal
                                                    medewerkers</span
                                                >
                                                <span
                                                    v-if="
                                                        !props.assembling
                                                            .canChangeAmount
                                                    "
                                                    >Het aantal
                                                    medewerkers</span
                                                >
                                            </label>
                                            <div
                                                class="e-form__input-wrap e-form__input-wrap--small e-form__input-wrap--product-modal"
                                            >
                                                <input
                                                    class="e-form__input e-form__input--small e-form__input--center-text"
                                                    type="number"
                                                    step="1"
                                                    :readonly="
                                                        !props.assembling
                                                            .canChangeAmount
                                                    "
                                                    :disabled="
                                                        !props.assembling
                                                            .canChangeAmount
                                                    "
                                                    v-model="
                                                        state.selection.amount
                                                    "
                                                />
                                            </div>
                                        </div>
                                        <div
                                            class="c-modal-product__input-description l-wysiwyg l-wysiwyg--super-small-text"
                                        ></div>
                                    </div>

                                    <div class="c-modal-product__input">
                                        <div
                                            class="c-modal-product__input-heading"
                                        >
                                            <label
                                                class="c-modal-product__label c-modal-product__input-label e-form__label"
                                                id="english-language"
                                            >
                                                <span>2.</span>
                                                <span
                                                    >Zijn er engelstalige
                                                    <br />medewerkers?</span
                                                >
                                            </label>
                                            <div
                                                class="e-form__input-wrap e-form__booleans"
                                            >
                                                <div
                                                    class="e-form__boolean-wrap"
                                                >
                                                    <input
                                                        class="e-form__boolean"
                                                        type="radio"
                                                        name="english-language"
                                                        id="no-english-language"
                                                        v-model="
                                                            state.selection
                                                                .english
                                                        "
                                                        :value="false"
                                                        checked
                                                    />
                                                    <label
                                                        class="e-form__boolean-label"
                                                        for="no-english-language"
                                                        >Nee</label
                                                    >
                                                </div>

                                                <div
                                                    class="e-form__boolean-wrap"
                                                >
                                                    <input
                                                        class="e-form__boolean"
                                                        type="radio"
                                                        name="english-language"
                                                        id="yes-english-language"
                                                        v-model="
                                                            state.selection
                                                                .english
                                                        "
                                                        :value="true"
                                                    />
                                                    <label
                                                        class="e-form__boolean-label"
                                                        for="yes-english-language"
                                                        >Ja</label
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="e-form e-form--labels-above"
                                            v-if="state.selection.english"
                                        >
                                            <div
                                                class="e-form__field-wrap mt-10"
                                            >
                                                <label
                                                    class="e-form__label"
                                                    for="english_count"
                                                    >Aantal engelstalige
                                                    medewerkers</label
                                                >
                                                <div class="e-form__input-wrap">
                                                    <input
                                                        class="e-form__input"
                                                        id="english_count"
                                                        type="number"
                                                        step="1"
                                                        v-model="
                                                            state.selection
                                                                .english_count
                                                        "
                                                        :class="{
                                                            'error-input-max-limit':
                                                                state.selection
                                                                    .amount <
                                                                state.selection
                                                                    .english_count,
                                                        }"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="c-modal-product__input">
                                        <div
                                            class="c-modal-product__input-heading"
                                        >
                                            <label
                                                class="c-modal-product__label c-modal-product__input-label e-form__label"
                                            >
                                                <span>3.</span>
                                                <span
                                                    >Zijn er medewerkers
                                                    <br />woonachtig in het
                                                    buitenland?</span
                                                >
                                            </label>
                                            <div
                                                class="e-form__input-wrap e-form__booleans"
                                            >
                                                <div
                                                    class="e-form__boolean-wrap"
                                                >
                                                    <input
                                                        class="e-form__boolean"
                                                        type="radio"
                                                        name="living-abroad"
                                                        id="no-living-abroad"
                                                        v-model="
                                                            state.selection
                                                                .foreigners
                                                        "
                                                        :value="false"
                                                        checked
                                                    />
                                                    <label
                                                        class="e-form__boolean-label"
                                                        for="no-living-abroad"
                                                        >Nee</label
                                                    >
                                                </div>

                                                <div
                                                    class="e-form__boolean-wrap"
                                                >
                                                    <input
                                                        class="e-form__boolean"
                                                        type="radio"
                                                        name="living-abroad"
                                                        id="yes-living-abroad"
                                                        v-model="
                                                            state.selection
                                                                .foreigners
                                                        "
                                                        :value="true"
                                                    />
                                                    <label
                                                        class="e-form__boolean-label"
                                                        for="yes-living-abroad"
                                                        >Ja</label
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="e-form e-form--labels-above"
                                            v-if="state.selection.foreigners"
                                        >
                                            <div
                                                class="e-form__field-wrap mt-10"
                                            >
                                                <label
                                                    class="e-form__label"
                                                    for="foreigners_count"
                                                    >Aantal medewerkers
                                                    woonachtig in het
                                                    buitenland</label
                                                >
                                                <div class="e-form__input-wrap">
                                                    <input
                                                        class="e-form__input"
                                                        id="foreigners_count"
                                                        step="1"
                                                        type="number"
                                                        v-model="
                                                            state.selection
                                                                .foreigners_count
                                                        "
                                                        :class="{
                                                            'error-input-max-limit':
                                                                state.selection
                                                                    .amount <
                                                                state.selection
                                                                    .foreigners_count,
                                                        }"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="c-modal-product__content">
                                    <div class="e-form__field">
                                        <div
                                            class="c-modal-product__form-field-title"
                                        >
                                            <label
                                                class="c-modal-product__label e-form__label"
                                                >4. Kies een
                                                geschenkverpakking</label
                                            >
                                        </div>

                                        <div
                                            class="c-modal-product__select-items e-form__select-items e-form__select-items--square"
                                        >
                                            <!--
                                            Geselecteerd class: e-form__select-item--selected
                                            Disabled class: e-form__select-item--disabled
                                        -->
                                            <div
                                                class="e-form__select-item"
                                                :class="{
                                                    'e-form__select-item--selected':
                                                        product.id ==
                                                        state.selection.giftbox,
                                                    'e-form__select-item--disabled':
                                                        product.is_sold_out,
                                                }"
                                                v-for="product in state.giftboxes"
                                                :key="product.id"
                                                :id="'giftbox' + product.id"
                                                @click="setGiftbox(product)"
                                            >
                                                <div
                                                    class="e-form__select-item-image-wrap"
                                                >
                                                    <img
                                                        class="e-form__select-item-image"
                                                        :src="product.image"
                                                        :alt="
                                                            product.article_nr
                                                        "
                                                    />
                                                </div>
                                                <p
                                                    class="e-form__select-item-name"
                                                >
                                                    {{ product.name }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="c-modal-product__content"
                                    v-if="
                                        state.presents &&
                                        state.presents.length > 0
                                    "
                                >
                                    <div class="e-form__field">
                                        <div
                                            class="c-modal-product__form-field-title"
                                        >
                                            <label
                                                class="c-modal-product__label e-form__label"
                                                >5. Kies een presentje</label
                                            >
                                        </div>

                                        <div
                                            class="c-modal-product__select-items e-form__select-items e-form__select-items--square"
                                        >
                                            <div
                                                class="e-form__select-item"
                                                :class="{
                                                    'e-form__select-item--selected':
                                                        product.id ==
                                                        state.selection.present,
                                                    'e-form__select-item--disabled':
                                                        product.is_sold_out ||
                                                        (product.insertion_costs &&
                                                            !props.assembling
                                                                .canChangeAmount),
                                                }"
                                                v-for="product in state.presents"
                                                :key="product.id"
                                                @click="setPresent(product)"
                                            >
                                                <div
                                                    class="e-form__select-item-image-wrap"
                                                >
                                                    <img
                                                        class="e-form__select-item-image"
                                                        :src="product.image"
                                                        :alt="
                                                            product.article_nr
                                                        "
                                                    />
                                                </div>
                                                <p
                                                    class="e-form__select-item-name"
                                                >
                                                    {{ product.name }}
                                                </p>
                                                <p
                                                    class="e-form__select-item-note"
                                                    v-if="
                                                        product.insertion_costs
                                                    "
                                                >
                                                    Kosten per presentje: <br />
                                                    {{
                                                        product.moneys
                                                            .insertion_costs
                                                    }}
                                                    excl. btw
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="c-modal-product__content">
                                    <div class="e-form__field">
                                        <div
                                            class="c-modal-product__form-field-title"
                                            v-if="state.is_email"
                                        >
                                            <label
                                                class="c-modal-product__label e-form__label"
                                                v-if="
                                                    state.presents &&
                                                    state.presents.length > 0
                                                "
                                                >6. Voeg hieronder de introtekst
                                                voor de mailing toe</label
                                            >
                                            <label
                                                class="c-modal-product__label e-form__label"
                                                v-if="
                                                    state.presents &&
                                                    state.presents.length == 0
                                                "
                                                >5. Voeg hieronder de introtekst
                                                voor de mailing toe</label
                                            >
                                        </div>

                                        <div
                                            class="c-modal-product__form-field-title"
                                            v-if="!state.is_email"
                                        >
                                            <label
                                                class="c-modal-product__label e-form__label"
                                                v-if="
                                                    state.presents &&
                                                    state.presents.length > 0
                                                "
                                                >6. Wil je kosteloos een
                                                wenskaart toevoegen?</label
                                            >
                                            <label
                                                class="c-modal-product__label e-form__label"
                                                v-if="
                                                    state.presents &&
                                                    state.presents.length == 0
                                                "
                                                >5. Wil je kosteloos een
                                                wenskaart toevoegen?</label
                                            >
                                        </div>

                                        <div
                                            class="e-form__input-wrap e-form__booleans e-form__booleans--center mt-20"
                                            v-if="!state.is_email"
                                        >
                                            <div class="e-form__boolean-wrap">
                                                <input
                                                    class="e-form__boolean"
                                                    type="radio"
                                                    v-model="
                                                        state.selection.card
                                                    "
                                                    name="wenskaart-option"
                                                    id="wenskaart-option-no"
                                                    :value="false"
                                                    checked
                                                />
                                                <label
                                                    class="e-form__boolean-label"
                                                    for="wenskaart-option-no"
                                                    >Nee</label
                                                >
                                            </div>

                                            <div class="e-form__boolean-wrap">
                                                <input
                                                    class="e-form__boolean"
                                                    v-model="
                                                        state.selection.card
                                                    "
                                                    type="radio"
                                                    name="wenskaart-option"
                                                    id="wenskaart-option-yes"
                                                    :value="true"
                                                />
                                                <label
                                                    class="e-form__boolean-label"
                                                    for="wenskaart-option-yes"
                                                    >Ja</label
                                                >
                                            </div>
                                        </div>

                                        <!--  If wenskaart -->
                                        <div
                                            class="e-form__input-wrap mt-20"
                                            v-show="state.selection.card"
                                        >
                                            <p v-if="!state.is_email">
                                                Welke tekst moet er in de
                                                wenskaart komen?
                                            </p>
                                            <textarea
                                                v-model="
                                                    state.selection.card_nl
                                                "
                                                class="c-modal-product__textarea e-form__textarea e-form__input--align-center mt-20"
                                                placeholder="Nederlandstalige wenskaart"
                                                maxlength="400"
                                            >
                                            </textarea>
                                            <div
                                                class="mt-10"
                                                v-if="
                                                    state.selection.english ==
                                                    true
                                                "
                                            >
                                                <p>Engels:</p>
                                                <textarea
                                                    v-model="
                                                        state.selection.card_en
                                                    "
                                                    class="c-modal-product__textarea e-form__textarea e-form__input--align-center mt-10"
                                                    placeholder="Engelse wenskaart"
                                                    maxlength="400"
                                                >
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="c-modal-product__content">
                                    <div class="e-form__field">
                                        <div class="e-modal__form-field-title">
                                            <label
                                                class="c-modal-product__label e-form__label"
                                                v-if="
                                                    state.presents &&
                                                    state.presents.length > 0
                                                "
                                                >7. Verzendmethode</label
                                            >
                                            <label
                                                class="c-modal-product__label e-form__label"
                                                v-if="
                                                    state.presents &&
                                                    state.presents.length == 0
                                                "
                                                >6. Verzendmethode</label
                                            >
                                        </div>
                                    </div>
                                    <div class="c-select-shipping">
                                        <!--  class shipping-selected op p tag if selected-->
                                        <p
                                            :class="{
                                                'shipping-selected':
                                                    state.selection.delivery ==
                                                    'Op locatie',
                                            }"
                                            @click="
                                                state.selection.delivery =
                                                    'Op locatie'
                                            "
                                            v-show="
                                                state.selection.delivery !=
                                                'Digitaal'
                                            "
                                        >
                                            Op locatie
                                        </p>

                                        <p
                                            :class="{
                                                'shipping-selected':
                                                    state.selection.delivery ==
                                                    'Huis-aan-huis',
                                            }"
                                            @click="
                                                state.selection.delivery =
                                                    'Huis-aan-huis'
                                            "
                                            v-show="
                                                state.selection.delivery !=
                                                'Digitaal'
                                            "
                                        >
                                            Huis-aan-huis
                                        </p>

                                        <p
                                            :class="{
                                                'shipping-selected':
                                                    state.selection.delivery ==
                                                    'Digitaal',
                                            }"
                                            @click="
                                                state.selection.delivery =
                                                    'Digitaal'
                                            "
                                            v-show="
                                                state.selection.delivery ==
                                                'Digitaal'
                                            "
                                        >
                                            Digitaal
                                        </p>
                                    </div>
                                </div>

                                <a
                                    class="e-button e-button--grey-dark e-button--super-big mt-20"
                                    :disabled="!state.selection.giftbox"
                                    @click="submitAssemble()"
                                    >Opslaan</a
                                >

                                <div class="c-modal-product__buttons">
                                    <div
                                        class="c-modal-product__cancel-link"
                                        @click="closeModal()"
                                    >
                                        Annuleren
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END PRODUCT SELECTION MODAL -->
    </transition>
</template>

<script setup>
import { useAuthClientStore } from "@/store/authClient";
import { useBoxStore } from "@/store/client/box";
import { useOrderStore } from "@/store/client/order";
import { useProductStore } from "@/store/client/product";
import { onMounted, onUpdated, reactive } from "vue";

const props = defineProps({
    assembling: {
        type: Object,
        default: {
            open: false,
        },
    },
    giftboxes: null,
});

const order = useOrderStore();
const box = useBoxStore();
const user = useAuthClientStore();

const product = useProductStore();

const state = reactive({
    giftboxes: {},
    presents: [],
    is_email: false,
    selection: {
        giftbox: null,
        present: null,
        delivery: "Op locatie",
        card: false,
        card_nl:
            "Bedankt voor je inzet dit jaar!\nWij wensen je een fijne kerst en een succesvol, gezond nieuw jaar!\n\nWarme groet,",
        card_en:
            "Thank you for your efforts this year!\nWe wish you a Merry Christmas and a successful, healthy New Year!\n\nWarm regards,",
        english: false,
        foreigners: false,
        english_count: 0,
        foreigners_count: 0,
        amount: 0,
    },
});

function closeModal() {
    Eventing.fire("closeAssembling");
}

onUpdated(() => {
    console.info("props.assembling", props.assembling);
    state.selection.amount = props.assembling.basket.amount;

    loadGiftboxes();
});

onMounted(() => {});

async function loadGiftboxes() {
    // alert(JSON.stringify(props.assembling.basket));
    let result = await product.giftboxes(props.assembling.basket.id);
    if (result) {
        state.giftboxes = result.data;
        state.selection.giftbox = state.giftboxes[0].id;

        try {
            setTimeout(() => {
                document
                    .getElementById("giftbox" + state.selection.giftbox)
                    .click();
            }, 1000);
        } catch (error) {}

        loadPresents(result.data[0].product_category_id);
    }
}

function setGiftbox(_product) {
    state.selection.giftbox = _product.id;

    if (_product.is_mailing) {
        state.is_email = true;
        state.selection.card = true;
    } else {
        state.is_email = false;
        state.selection.card = false;
    }

    if (_product.is_digital || _product.is_mailing) {
        state.selection.delivery = "Digitaal";
    } else {
        state.selection.delivery = "Op locatie";
    }
    state.selection.present = null;
    state.presents = [];
    loadPresents(_product.slug);
}

function setPresent(_product) {
    state.selection.present = _product.id;
}

async function submitAssemble() {
    let result = await product.assemble(
        props.assembling.basket.id,
        state.selection
    );
    if (result) {
        Eventing.fire("isAssembled");
    }
}

async function loadPresents(slug) {
    let result = await product.presents(
        slug + "?presents=true&basket=" + props.assembling.basket.id
    );
    if (result) {
        state.presents = result.data;
        try {
            state.selection.present = state.presents[0].id;
        } catch (error) {
            state.selection.present = null;
        }
    }
}
</script>

<style scoped>
.error-input-max-limit {
    background: tomato !important;
    color: white !important;
    font-weight: bold;
}
</style>

<template>
    <div class="c-page-content">
        <div
            class="c-page-content__container c-page-content__container--spacing-small c-page-content__container--no-pb c-page-content__container--dont-move-if-side-overlay-is-open"
        >
            <div class="c-page-filter e-form--transparent">
                <div class="c-page-filter__inner-wrap" v-if="state.runs">
                    <div class="c-page-filter__button">
                        <SvgFilter />
                    </div>
                    <div class="c-page-filter__options js-overflow-arrow">
                        <div
                            class="e-form__field-wrap"
                            v-for="(item, index) in state.runs"
                            :key="index"
                        >
                            <div class="e-form__label">
                                <p>{{ item.type }}</p>
                            </div>
                            <div class="e-form__select-wrap">
                                <select
                                    class="e-form__select e-form__select--small"
                                    placeholder="Run"
                                    v-model="state.filter[item.type]"
                                    @change="loadingResults()"
                                >
                                    <option placeholder="Run" value="">
                                        Alle runs
                                    </option>
                                    <option
                                        class="e-form__input-list-item"
                                        v-for="run in item.items"
                                        :key="run.id"
                                        :value="run.id"
                                    >
                                        {{ run.name }} ({{ run.pretty_date }})
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div
                            class="e-form__field-wrap"
                            v-if="product && product.categories"
                        >
                            <div class="e-form__label">
                                <p>Categorie</p>
                            </div>
                            <div class="e-form__select-wrap">
                                <select
                                    class="e-form__select e-form__select--small"
                                    placeholder="Categorie"
                                    v-model="state.filter['Category']"
                                    @change="
                                        loadingResults();
                                        state.currentProduct = '';
                                    "
                                >
                                    <option placeholder="Categorie" value="">
                                        Alle categorieën
                                    </option>
                                    <option
                                        v-for="(
                                            option, value
                                        ) in product.categories"
                                        v-bind:value="option.id"
                                        :key="value"
                                    >
                                        {{ option.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div
                            class="e-form__field-wrap"
                            v-if="product && state.products"
                        >
                            <div class="e-form__label">
                                <p>Product</p>
                            </div>
                            <div class="e-form__select-wrap">
                                <select
                                    class="e-form__select e-form__select--small"
                                    placeholder="Categorie"
                                    no-v-model="state.filter['Product']"
                                    v-model="state.currentProduct"
                                >
                                    <option placeholder="Product" value="">
                                        Alle maatwerk producten
                                    </option>
                                    <option
                                        v-for="(
                                            option, value
                                        ) in state.products"
                                        v-bind:value="option.article_nr"
                                        :disabled="
                                            state.filter['Category'] != '' &&
                                            option.product_category_id !=
                                                state.filter['Category']
                                        "
                                        :key="value"
                                    >
                                        {{ option.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!state.isLoaded" class="e-preloader"></div>

            <div class="c-status-columns" v-if="state.results">
                <div class="c-status-columns__columns">
                    <div
                        class="c-status-columns__column"
                        v-for="(result, value) in state.results"
                        :key="value"
                    >
                        <div class="c-status-columns__column-heading">
                            <h2>
                                {{ result.status }}
                                <span
                                    class="c-status-columns__column-count e-label e-label--medium e-label--green transition-all ease-in-out delay-500"
                                    >{{
                                        result?.items
                                            ? getVisibleItemCount(result.items)
                                            : result.items.length
                                    }}</span
                                >
                            </h2>
                        </div>
                        <div class="c-status-columns__items">
                            <draggable
                                class="list-group drop-area"
                                :list="result.items"
                                group="products"
                                :sort="false"
                                @end="saveSortings"
                                itemKey="name"
                                filter=".c-order-tile__icon--button, .e-button, .date-picker, input"
                                preventOnFilter="false"
                            >
                                <template #item="{ element, index }">
                                    <div class="list-group-item">
                                        <maatwerk-tile
                                            @click="toggleOrder(element)"
                                            :index="index"
                                            :test="element.id.toString()"
                                            :item="element"
                                            :order="element"
                                            :status="result.status"
                                            :runs="state.runs"
                                            :class="{
                                                'no-focus':
                                                    state.filter['Category'] !=
                                                        '' &&
                                                    state.filter['Category'] !=
                                                        element.product_category_id,
                                            }"
                                            v-show="
                                                state.currentProduct.length <=
                                                    0 ||
                                                (state.currentProduct.length >
                                                    2 &&
                                                    element.article_nr ==
                                                        state.currentProduct)
                                            "
                                        ></maatwerk-tile>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-dashboards-side-overlay-design />
</template>

<style scoped>
.no-focus {
    display: none;
    opacity: 0.5;
}
</style>

<script setup>
import { useDashboardDesignStore } from "@/store/dashboard/design";
import { useDashboardRunsStore } from "@/store/dashboard/runs";
import { useProductStore } from "@/store/product";
import SvgFilter from "@/svg/filter.svg";
import { inject, nextTick, onMounted, onUpdated, reactive } from "vue";

import draggable from "vuedraggable";

const Modals = inject("Modals");

function log(evt) {
    window.console.log(evt);
}

const runs = useDashboardRunsStore();
const design = useDashboardDesignStore();
const product = useProductStore();

onUpdated(() => {
    Eventing.fire("stickyElement");
    Eventing.fire("toggleSideOverlay");
    Eventing.fire("addOverflowArrows");
});

const state = reactive({
    url: "/api/dashboards/design/orders?q",
    orderBy: "desc",
    results: null,
    filter: { Drukkerij: "", Magazijn: "", Category: "", Product: "" },
    runs: null,
    order: null,
    products: null,
    currentProduct: "",
    isLoaded: false,
});

async function loadRuns() {
    let fill = await runs.fill();
    if (fill) {
        state.runs = fill;
    }

    product.fillCategoriesMaatwerk();

    let result = await product.fillCategoriesMaatwerkProducts();
    if (result) {
        state.products = result;
    }
}
loadRuns();

onMounted(() => {
    Eventing.listen("reloadItemsAndKeepSidebarOpen", async () => {
        loadingResultsKeepSideoverlayOpen();
    });

    Eventing.listen("reloadItems", () => {
        if (document.body.classList.contains("body--side-overlay-is-open")) {
            document.body.classList.remove("body--side-overlay-is-open");
        }
        loadingResults();
    });
});

async function loadingResults() {
    Eventing.fire("clearSideOverlay");
    state.url = "/api/dashboards/design/orders?q";
    state.order = null;
    //state.currentProduct = "";
    let results = await design.fill(state.url, state.filter);
    if (results) {
        state.results = results;
    }

    await nextTick();

    state.isLoaded = true;
}
loadingResults();

async function loadingResultsKeepSideoverlayOpen() {
    state.url = "/api/dashboards/design/orders?q";
    let results = await design.fill(state.url, state.filter);
    if (results) {
        state.results = results;
    }
}

async function saveSortings() {
    let data = await design.saveStatus({
        statusses: state.results,
    });
    if (data) {
        loadingResults();
    }
}

function getVisibleItemCount(items) {
    try {
        return items.filter((item) => {
            var isNoFocus = false;
            var isVisible = true;
            try {
                isVisible =
                    state.currentProduct.length <= 0 ||
                    (state.currentProduct.length > 2 &&
                        item.article_nr === state.currentProduct);
            } catch (error) {
                console.error("getVisibleItemCount@isVisible", error);
            }

            try {
                isNoFocus =
                    state.filter["Category"] != "" &&
                    state.filter["Category"] != item.product_category_id;
            } catch (error) {
                console.error("getVisibleItemCount@isNoFocus", error);
            }

            return isVisible && !isNoFocus;
        }).length;
    } catch (error) {
        console.error("getVisibleItemCount@catch", error);
        return items.length;
    }
}

async function toggleOrder(basket) {
    if (state.order && state.order.hash == basket.hash) {
        state.order = null;

        if (document.body.classList.contains("body--side-overlay-is-open")) {
            document.body.classList.remove("body--side-overlay-is-open");
        }
    } else {
        Eventing.fire("clearSideOverlay");

        let result = await design.show(basket.hash);
        if (result) {
            state.order = result;
            document.body.classList.add("body--side-overlay-is-open");
            Eventing.fire("fillSideOverlay", result);

            Eventing.fire("fillRuns", state.runs);
        }

        let resultBasket = await design.basket(basket.id);
        if (resultBasket) {
            console.info("resultBasket", resultBasket);
            Eventing.fire("fillSideOverlayBasket", resultBasket);
        }
    }
}
</script>

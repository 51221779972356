<template>
    <section>
        <div
            class="e-note mt-10"
            v-if="state.box && !state.box.shipping_method"
        >
            <SvgInfo />
            <div class="e-note__text">
                <p>
                    <b>{{ state.box.name }}</b>
                </p>
                <p>
                    Kies eerst de type bezorging alvorens er afleveradressen
                    toegevoegd kunnen worden
                </p>
            </div>
        </div>

        <section v-if="state.box && state.box.shipping_method">
            <h2
                class="c-page-content__sub-section-title"
                v-if="order.has_varying_deliveries"
            >
                <SvgGift /> {{ state.box.name }}
                <span
                    class="c-page-content__sub-section-title-right c-page-content__sub-section-title-note"
                    v-if="state.addresses && state.addresses.length > 1"
                    ><span>{{ state.addresses.length }}</span> adressen

                    <a @click="exportAddresses(state.box.hash)">
                        <svg
                            width="16"
                            height="16"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                            ></path>
                        </svg>
                    </a>
                </span>
            </h2>

            <h2
                class="c-page-content__sub-section-title"
                v-if="!order.has_varying_deliveries"
            >
                <span
                    class="c-page-content__sub-section-title-right c-page-content__sub-section-title-note"
                    v-if="state.addresses && state.addresses.length > 1"
                    ><span>{{ state.addresses.length }}</span> adressen
                    <small v-if="state.duplicates > 0" class="tomato"
                        >&nbsp;({{ state.duplicates }} dubbel)&nbsp;</small
                    >

                    <a @click="exportAddresses(state.box.hash)">
                        <svg
                            width="16"
                            height="16"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                            ></path>
                        </svg>
                    </a>
                </span>
            </h2>

            <div
                class="c-table-simple c-table-simple mt-10"
                v-if="state.addresses && state.addresses.length >= 0"
            >
                <div
                    class="c-table-simple__bottom-button e-button e-button--green"
                    v-if="state.box.shipping_method != 'Digitaal'"
                    @click="newAdress(state.box.shipping_method)"
                >
                    Opslaan
                </div>
                <div
                    class="c-table-simple__inner-wrap"
                    v-if="state.box.shipping_method != 'Digitaal'"
                >
                    <div class="c-table-simple__table">
                        <div class="c-table-simple__head">
                            <div class="c-table-simple__tr">
                                <div
                                    class="c-table-simple__td"
                                    v-if="
                                        state.box.shipping_method ==
                                        'Op locatie'
                                    "
                                >
                                    Bedrijf
                                </div>
                                <div
                                    class="c-table-simple__td"
                                    v-if="
                                        state.box.shipping_method ==
                                        'Op locatie'
                                    "
                                >
                                    Contactpersoon
                                </div>
                                <div
                                    class="c-table-simple__td"
                                    v-if="
                                        state.box.shipping_method ==
                                        'Huis-aan-huis'
                                    "
                                >
                                    Geadresseerde
                                </div>
                                <div
                                    class="c-table-simple__td"
                                    v-if="
                                        state.box.shipping_method ==
                                        'Huis-aan-huis'
                                    "
                                >
                                    Achternaam
                                </div>
                                <div class="c-table-simple__td">Postcode</div>
                                <div class="c-table-simple__td">Huisnr.</div>
                                <div class="c-table-simple__td">Adres</div>
                                <div class="c-table-simple__td">Stad</div>
                                <div class="c-table-simple__td">Land</div>
                                <div class="c-table-simple__td">&nbsp;</div>
                            </div>
                        </div>
                        <div
                            class="c-table-simple__body"
                            @keydown="box.errors.errors = {}"
                        >
                            <article
                                class="c-table-simple__tr"
                                v-for="(item, index) in state.addresses"
                                :key="index"
                            >
                                <div class="c-table-simple__td">
                                    {{ item.name }}
                                </div>
                                <div class="c-table-simple__td">
                                    {{ item.lastname }}
                                </div>
                                <div class="c-table-simple__td">
                                    {{ item.postcode }}
                                </div>
                                <div class="c-table-simple__td">
                                    {{ item.housenumber }}
                                </div>
                                <div class="c-table-simple__td">
                                    {{ item.address }}
                                </div>
                                <div class="c-table-simple__td">
                                    {{ item.city }}
                                </div>
                                <div class="c-table-simple__td">
                                    {{ item.country }}
                                </div>

                                <div class="c-table-simple__td">
                                    <button
                                        class="c-order-item__delete"
                                        @click="deleteAddress(item)"
                                    >
                                        <SvgDelete />
                                    </button>
                                </div>
                            </article>
                            <form
                                class="c-table-simple__tr"
                                v-if="showFormOrNot()"
                            >
                                <div class="c-table-simple__td">
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                box.errors.message &&
                                                box.errors.errors.name,
                                        }"
                                        :invalid-message="
                                            box.errors.errors.name
                                        "
                                    >
                                        <input
                                            type="text"
                                            v-model="state.form.name"
                                            class="e-form__input"
                                            :placeholder="
                                                state.box.shipping_method ==
                                                'Huis-aan-huis'
                                                    ? 'Geadresseerde'
                                                    : 'Bedrijfsnaam'
                                            "
                                        />
                                    </div>
                                </div>

                                <div class="c-table-simple__td">
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                box.errors.message &&
                                                box.errors.errors.lastname,
                                        }"
                                        :invalid-message="
                                            box.errors.errors.lastname
                                        "
                                    >
                                        <input
                                            type="text"
                                            v-model="state.form.lastname"
                                            class="e-form__input"
                                            :placeholder="
                                                state.box.shipping_method ==
                                                'Huis-aan-huis'
                                                    ? 'Achternaam'
                                                    : 'Contactpersoon'
                                            "
                                        />
                                    </div>
                                </div>

                                <div class="c-table-simple__td">
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                box.errors.message &&
                                                box.errors.errors.postcode,
                                        }"
                                        :invalid-message="
                                            box.errors.errors.postcode
                                        "
                                    >
                                        <input
                                            type="text"
                                            v-model="state.form.postcode"
                                            placeholder="0000AA"
                                            class="e-form__input e-form__input--zipcode"
                                        />
                                    </div>
                                </div>
                                <div class="c-table-simple__td">
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                box.errors.message &&
                                                box.errors.errors.housenumber,
                                        }"
                                        :invalid-message="
                                            box.errors.errors.housenumber
                                        "
                                    >
                                        <input
                                            type="text"
                                            v-model="state.form.housenumber"
                                            not-keyup="searchAddressByPostcode()"
                                            class="e-form__input e-form__input--housenumber"
                                            placeholder="0"
                                        />
                                    </div>
                                </div>
                                <div class="c-table-simple__td">
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                box.errors.message &&
                                                box.errors.errors.address,
                                        }"
                                        :invalid-message="
                                            box.errors.errors.address
                                        "
                                    >
                                        <input
                                            type="text"
                                            v-model="state.form.address"
                                            placeholder="Straatnaam"
                                            class="e-form__input"
                                        />
                                    </div>
                                </div>
                                <div class="c-table-simple__td">
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                box.errors.message &&
                                                box.errors.errors.city,
                                        }"
                                        :invalid-message="
                                            box.errors.errors.city
                                        "
                                    >
                                        <input
                                            type="text"
                                            v-model="state.form.city"
                                            placeholder="Plaatsnaam"
                                            class="e-form__input"
                                        />
                                    </div>
                                </div>
                                <div class="c-table-simple__td">
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                box.errors.message &&
                                                box.errors.errors.country,
                                        }"
                                        :invalid-message="
                                            box.errors.errors.country
                                        "
                                    >
                                        <input
                                            type="text"
                                            v-model="state.form.country"
                                            placeholder="Land"
                                            class="e-form__input"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div
                    class="c-table-simple__inner-wrap"
                    v-if="state.box.shipping_method == 'Digitaal'"
                >
                    <div class="c-table-simple__table">
                        <div class="c-table-simple__head">
                            <div class="c-table-simple__tr">
                                <div class="c-table-simple__td">Voornaam</div>
                                <div class="c-table-simple__td">Achternaam</div>
                                <div class="c-table-simple__td">
                                    E-mailadres
                                </div>
                                <div class="c-table-simple__td">&nbsp;</div>
                            </div>
                        </div>
                        <div
                            class="c-table-simple__body"
                            @keydown="box.errors.errors = {}"
                        >
                            <article
                                class="c-table-simple__tr"
                                v-for="(item, index) in state.addresses"
                                :key="index"
                            >
                                <div class="c-table-simple__td">
                                    {{ item.name }}
                                </div>
                                <div class="c-table-simple__td">
                                    {{ item.lastname }}
                                </div>
                                <div class="c-table-simple__td">
                                    {{ item.email }}
                                </div>

                                <div class="c-table-simple__td">
                                    <button
                                        class="c-order-item__delete"
                                        @click="deleteAddress(item)"
                                    >
                                        <SvgDelete />
                                    </button>
                                </div>
                            </article>
                            <form
                                class="c-table-simple__tr"
                                v-if="showFormOrNot()"
                            >
                                <div class="c-table-simple__td">
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                box.errors.message &&
                                                box.errors.errors.name,
                                        }"
                                        :invalid-message="
                                            box.errors.errors.name
                                        "
                                    >
                                        <input
                                            type="text"
                                            v-model="state.form.name"
                                            class="e-form__input"
                                            placeholder="Voornaam"
                                        />
                                    </div>
                                </div>

                                <div class="c-table-simple__td">
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                box.errors.message &&
                                                box.errors.errors.lastname,
                                        }"
                                        :invalid-message="
                                            box.errors.errors.lastname
                                        "
                                    >
                                        <input
                                            type="text"
                                            v-model="state.form.lastname"
                                            class="e-form__input"
                                            placeholder="Achternaam"
                                        />
                                    </div>
                                </div>

                                <div class="c-table-simple__td">
                                    <div
                                        class="e-form__field-wrap"
                                        :class="{
                                            'e-form__field-wrap--invalid':
                                                box.errors.message &&
                                                box.errors.errors.email,
                                        }"
                                        :invalid-message="
                                            box.errors.errors.email
                                        "
                                    >
                                        <input
                                            type="email"
                                            v-model="state.form.email"
                                            placeholder="E-mailadres"
                                            class="e-form__input"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="e-note mt-10"
                v-if="
                    (state.box.shipping_method == 'Huis-aan-huis' ||
                        state.box.shipping_method == 'Digitaal') &&
                    state.uploadProgress < 100
                "
            >
                <SvgInfo />
                <div class="e-note__text">
                    <p v-if="state.box.shipping_method == 'Digitaal'">
                        Upload een Excel-bestand met alle e-mailaddressen.
                        <b>Let op:&nbsp;</b>
                        <a
                            @click="
                                downloadExcel(
                                    state.box.hash,
                                    'Naam,Achternaam,Email',
                                    'import-sheet-' + state.box.shipping_method
                                )
                            "
                            target="_blank"
                            >download het voorbeeld Excel-bestand</a
                        >
                        of gebruik de volgende structuur: &nbsp;
                        <kbd>Naam | Achternaam | Email</kbd>.
                    </p>
                    <p v-if="state.box.shipping_method == 'Huis-aan-huis'">
                        Upload een Excel-bestand met alle afleveradressen.
                        <b>Let op:&nbsp;</b>
                        <a
                            @click="
                                downloadExcel(
                                    state.box.hash,
                                    'Naam,Achternaam,Adres,Huisnummer,Postcode,Plaats,Land',
                                    'import-sheet-' + state.box.shipping_method
                                )
                            "
                            >download het voorbeeld Excel-bestand</a
                        >
                        of gebruik de volgende structuur: &nbsp;
                        <kbd
                            >Naam | Achternaam | Adres | Huisnummer | Postcode |
                            Plaats | Land</kbd
                        >.
                    </p>
                </div>
            </div>

            <div
                class="c-files__files c-files__files--no-padding"
                v-if="state.box && state.box.shipping_method != 'Op locatie'"
            >
                <h2 class="c-files__upload-subject mt-20" v-if="false">
                    Afleveraddressen:
                </h2>

                <div
                    class="c-files__file-upload"
                    v-if="state.uploadProgress < 100"
                >
                    <div class="e-form__upload-area">
                        <input
                            class="e-form__upload-area-input"
                            type="file"
                            ref="uploadFile"
                            required
                            accept=".xlsx, .xls"
                            v-on:change="
                                handleFileUpload(
                                    'imports/' + state.box.hash,
                                    state.box.hash
                                )
                            "
                        />
                        <div class="e-form__upload-area-text">
                            <div class="e-form__upload-area-text-uploading">
                                Uploading...
                            </div>
                            <div class="e-form__upload-area-text-default">
                                Selecteer bestanden om te uploaden
                            </div>
                            <progress
                                v-if="
                                    state.uploadProgress > 0 &&
                                    state.uploadProgress < 100
                                "
                                :value="state.uploadProgress"
                                max="100"
                            />
                        </div>
                    </div>
                </div>

                <h2 class="c-files__upload-subject mt-20" v-if="false">
                    Buitenlandse afleveraddressen:
                </h2>

                <div
                    class="c-files__file-upload"
                    v-if="state.uploadProgress < 100 && false"
                >
                    <div class="e-form__upload-area">
                        <input
                            class="e-form__upload-area-input"
                            type="file"
                            ref="uploadFile"
                            required
                            accept=".xlsx, .xls"
                            v-on:change="
                                handleFileUpload(
                                    'imports/' + state.box.hash,
                                    state.box.hash
                                )
                            "
                        />
                        <div class="e-form__upload-area-text">
                            <div class="e-form__upload-area-text-uploading">
                                Uploading...
                            </div>
                            <div class="e-form__upload-area-text-default">
                                Selecteer bestanden om te uploaden
                            </div>
                            <progress
                                v-if="
                                    state.uploadProgress > 0 &&
                                    state.uploadProgress < 100
                                "
                                :value="state.uploadProgress"
                                max="100"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script setup>
import SvgGift from "@/svg/gift.svg";
import SvgInfo from "@/svg/info.svg";

import SvgDelete from "@/svg/delete.svg";

import { useAuthStore } from "@/store/auth";
import { useBoxStore } from "@/store/box";
import { useUploadStore } from "@/store/upload";
import Swal from "sweetalert2";

import { onMounted, reactive, ref } from "vue";

import Vapor from "laravel-vapor";
const uploadFile = ref(null);

const box = useBoxStore();
const user = useAuthStore();
const upload = useUploadStore();

const props = defineProps({
    order: Object,
    box: Object,
    auth: { type: String, required: false, default: "User" },
});

box.fillAddresses(props.box.hash);

const state = reactive({
    form: {
        name: null,
        postcode: null,
        housenumber: null,
        address: null,
        city: null,
        email: null,
        shippingMethod: null,
    },
    order: {
        hash: null,
        has_varying_deliveries: false,
        contact: {
            address: {},
        },
        followers: {},
        boxes: null,
        shipping_method: null,
    },
    box: null,
    addresses: null,
    uploadProgress: 0,
    duplicates: 0,
});

onMounted(() => {
    state.order = props.order;
    state.box = props.box;

    Eventing.listen("fillAddress." + props.box.hash, (data) => {
        state.addresses = data.data;
        state.duplicates = data.doubles;
        state.form = {
            name: null,
            postcode: null,
            housenumber: null,
            address: null,
            city: null,
            email: null,
            shippingMethod: null,
        };
        Eventing.fire("reloadDelivery");
    });

    Eventing.listen("addAddress." + props.box.hash, (address) => {
        state.addresses.push(address);
        Eventing.fire("reloadDelivery");
    });
});

function showFormOrNot() {
    try {
        if (state.box.shipping_method == "Op locatie") {
            if (state.addresses.length >= 1) {
                return false;
            }
        }
    } catch (error) {}

    return true;
}

function deleteAddress(item) {
    Swal.fire({
        text: "Zeker weten dat dit adres wilt verwijderen?",
        confirmButtonText: "Wissen",
        showCloseButton: true,
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            box.destroyAddress(props.box.hash, item.id);
        }
    });
}

function newAdress(shippingMethod) {
    state.form.shippingMethod = shippingMethod;
    box.storeAddress(props.box.hash, state.form);
}

function searchAddressByPostcode() {
    axios
        .post("/api/external/postcode", state.form, box.auth())
        .then((response) => {
            console.warn("postcode");
            if (response.data.streetname) {
                if (response.data.streetname) {
                    state.form.address = response.data.streetname;
                    state.form.city = response.data.city;
                    state.form.postcode = response.data.postcode;
                    state.form.country = "NLD";
                }
            }
        })
        .catch((error) => console.error(error));
}

function downloadExcel(_box, fields, _filename) {
    axios({
        url: "/api/boxes/" + _box + "/addresses/" + fields,
        method: "GET",
        // responseType: "blob",
        // withCredential: false,
        headers: {
            Authorization: "Bearer " + user.token(),
            // Order: user.order(),
        },
    })
        .then((response) => {
            let result = response;
            const url = result.data.url;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", result.data.filename);
            document.body.appendChild(link);
            link.click();
            // FileDownload(response.data, _filename.toLowerCase() + ".xls");
            Eventing.fire(
                "toasting",
                "Download is gestart: '" + _filename.toLowerCase() + ".xls'",
                "info"
            );
        })
        .catch((error) => console.error(error));
}

function exportAddresses(_box) {
    axios({
        url: "/api/boxes/" + _box + "/addresses/export",
        method: "GET",
        // responseType: "blob",
        // withCredential: false,
        headers: {
            Authorization: "Bearer " + user.token(),
            // Order: user.order(),
        },
    })
        .then((response) => {
            let result = response;
            const url = result.data.url;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", result.data.filename);
            document.body.appendChild(link);
            link.click();
            // FileDownload(response.data, _filename.toLowerCase() + ".xls");
            Eventing.fire(
                "toasting",
                "Download is gestart: '" + _filename.toLowerCase() + ".xls'",
                "info"
            );
        })
        .catch((error) => console.error(error));
}

function handleFileUpload(folder, box) {
    let file = uploadFile.value.files[0];
    Vapor.store(file, {
        progress: (progress) => {
            state.uploadProgress = Math.round(progress * 100);
        },
        headers: { Authorization: "Bearer " + user.token() },
    }).then((response) => {
        let form = {
            uuid: response.uuid,
            key: response.key,
            bucket: response.bucket,
            name: file.name,
            content_type: file.type,
            folder: folder,
            box: box,
        };
        upload.store("addresses-box", form);
        setTimeout(() => {
            // Eventing.fire("userChanged", state.form);
        }, 1000);
    });
}
</script>

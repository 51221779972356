<template>
    <client-header />
    <section>
        <Assemble :assembling="state.assembling"></Assemble>

        <div class="c-client-toolbar">
            <div class="c-client-toolbar__inner">
                <router-link
                    class="e-button e-button--grey-dark e-button--full-width"
                    :to="{ name: 'client.files' }"
                    @click="scrollToPageContent"
                >
                    Bestelling compleet? Ga naar <span>stap 2</span> en voeg
                    bestanden toe.
                    <div class="e-button__arrow-right"></div>
                </router-link>
            </div>
        </div>

        <div class="c-page-content">
            <div
                class="c-page-content__container c-page-content__container--spacing"
            >
                <section class="c-page-content__section">
                    <div
                        class="e-note e-note--regular e-note--vertical-align"
                        v-if="
                            (state.order.status &&
                                state.order.status.label &&
                                !state.order.status.label.includes(
                                    'In ontvangst'
                                ) &&
                                !state.order.status.label.includes(
                                    'Concept'
                                )) ||
                            (state.order.invoice_status &&
                                state.order.invoice_status.label.includes(
                                    'Gefactureerd'
                                ))
                        "
                    >
                        <div class="e-note__icon">
                            <div class="e-note__icon-circle-animation">
                                <SvgInfo />
                            </div>
                        </div>
                        <div class="e-note__text">
                            <p
                                v-if="
                                    !state.order.status.label.includes(
                                        'In ontvangst'
                                    ) &&
                                    !state.order.status.label.includes(
                                        'Concept'
                                    )
                                "
                            >
                                De bestelling kan door de status "<b>{{
                                    state.order.status.label
                                }}</b
                                >" niet meer gewijzigd worden.
                            </p>

                            <p
                                v-if="
                                    state.order.invoice_status.label.includes(
                                        'Gefactureerd'
                                    ) &&
                                    state.order.status.label.includes(
                                        'In ontvangst'
                                    )
                                "
                            >
                                De bestelling kan door de factuur status "<b>{{
                                    state.order.invoice_status.label
                                }}</b
                                >" niet worden uitgebreid met betaalde
                                toevoegingen.
                            </p>
                        </div>
                    </div>
                    <section
                        class="c-page-content__section"
                        :class="{
                            'c-page-content__section--disabled':
                                state.order.status &&
                                state.order.status.label &&
                                !state.order.status.label.includes(
                                    'In ontvangst'
                                ) &&
                                !state.order.status.label.includes('Concept'),
                        }"
                    >
                        <!--  DEV Note: if status 'Kan in productie', add class c-page-content__section--disabled -->

                        <div class="c-order__packages-header">
                            <div class="c-order-item__col">
                                <div
                                    class="c-order-item__amount"
                                    @click="checkForAssembling()"
                                >
                                    Aantal
                                </div>
                                <button class="c-order-item__delete"></button>
                            </div>
                        </div>

                        <!--  ORDER PACKAGE -->
                        <div
                            class="c-order__package"
                            v-for="(box, index) in box.boxes"
                            :key="box.hash + index"
                        >
                            <div class="e-section-heading">
                                <div class="e-section-heading__left">
                                    <div class="e-section-heading__icon">
                                        <SvgGift />
                                    </div>
                                    <div class="e-edit-toggle">
                                        <div class="e-edit-toggle__output-wrap">
                                            <h2 class="e-section-heading__name">
                                                {{ box.name }}
                                            </h2>

                                            <span v-if="box.missing_count > 0">
                                                <!-- <span>{{box.missing_count}}</span> -->
                                                <!-- &nbsp;🚨 -->
                                            </span>
                                            <button
                                                class="e-edit-toggle__edit-icon js-edit-toggle"
                                            >
                                                <SvgEdit />
                                            </button>
                                        </div>
                                        <div
                                            class="e-edit-toggle__input-wrap e-edit-toggle__input-wrap--save-icon-right"
                                        >
                                            <div
                                                class="e-edit-toggle__input-wrap-row"
                                            >
                                                <input
                                                    type="text"
                                                    class="e-form__input e-edit-toggle__input"
                                                    v-model="box.name"
                                                />
                                                <button
                                                    class="e-edit-toggle__save-icon js-edit-toggle"
                                                    @click="changeBoxName(box)"
                                                >
                                                    <SvgCheck />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="e-section-heading__right"></div>
                            </div>
                            <div class="c-order__package-contents js-drag-area">
                                <!-- @foreach(range(1, 2) as $i) -->
                                <!--  ORDER ITEM -->
                                <div
                                    class="c-order-item js-drag-handle js-draggable"
                                    v-for="(basket, subindex) in box.baskets"
                                    :key="basket.id + subindex"
                                >
                                    <client-basket-item
                                        :order="user.order()"
                                        :basket="basket"
                                        :box="box.hash"
                                        :product="basket.product"
                                        :first-of-category="
                                            basket.product &&
                                            basket.first_of_categories.includes(
                                                basket.product.article_nr
                                            )
                                        "
                                    />
                                </div>

                                <a
                                    class="e-button e-button--full-width mt-10"
                                    href="#/klant/communicatie"
                                >
                                    <p>
                                        Wijzigingen doorvoeren? Neem contact op
                                        met onze trafficmanagers.
                                    </p>
                                </a>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </div>
    </section>
</template>

<script setup>
import SvgCheck from "@/svg/check.svg";
import SvgDelete from "@/svg/delete.svg";
import SvgEdit from "@/svg/edit.svg";
import SvgGift from "@/svg/gift.svg";
import SvgInfo from "@/svg/info.svg";

import { useAuthClientStore } from "@/store/authClient";
import { useBoxStore } from "@/store/client/box";
import { useOrderStore } from "@/store/client/order";
import Swal from "sweetalert2";
import { inject, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

import Assemble from "./Assemble.vue";

const Modals = inject("Modals");
const order = useOrderStore();
const box = useBoxStore();
const user = useAuthClientStore();

const route = useRoute();

const state = reactive({
    order: {
        contact: {
            address: {},
        },
        followers: {},
    },
    assembling: {
        open: false,
    },
});

onMounted(() => {
    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
    });

    setTimeout(() => {
        checkForAssembling();
    }, 2000);

    Eventing.listen("closeAssembling", () => {
        state.assembling.open = false;
    });

    Eventing.listen("openAssembleModal", (box, basket) => {
        console.info("openAssembleModal", box);
        state.assembling = {
            canChangeAmount: state.order?.invoice_status?.label?.includes(
                "Gefactureerd"
            )
                ? false
                : true,
            open: true,
            box: box,
            basket: basket,
        };
    });

    order.show(user.order());

    box.fill(user.order());

    Eventing.listen("isAssembled", () => {
        order.show(user.order());
        window.location.reload(true);
        state.assembling.open = false;
    });

    Eventing.listen("reloadOrder", () => {
        setTimeout(() => {
            order.show(user.order());
        }, 1500);
    });

    Eventing.listen("addProductToBox", (box, form) => {
        addToBasket(box, form);
    });
});

function checkForAssembling() {
    if (document.querySelectorAll(".assembler-btn").length > 0) {
        // @W: en hier kun je dan dat element voor wizjigignen removen of hiden obv classname, want op dit moment is er een "samenstel" knop zichtbaar die zich kan openen
        setTimeout(() => {
            document.querySelectorAll(".assembler-btn")[0].click();
        }, 1750);
        return true;
    }
    return false;
}

function changeBoxName(_box) {
    box.updateBoxName(user.order(), _box.hash, { name: _box.name });
}

function addToBasket(_box, form) {
    box.storeBasket(_box, form);
}

function addNewBox() {
    Swal.fire({
        text: "Zeker weten dat je een samenstelling wil toevoegen?",
        confirmButtonText: "Toevoegen",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "Annuleren",
        icon: "question",
    }).then((result) => {
        if (result.isConfirmed) {
            box.newBox(user.order());
        }
    });
}

function deleteBox(_box) {
    Swal.fire({
        text: "Zeker weten dat je de samenstelling wil verwijderen?",
        confirmButtonText: "Wissen",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "Annuleren",
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            box.deleteBox(user.order(), _box.hash);
        }
    });
}
</script>

<template>
    <article>
        <div class="c-client-header">
            <div class="c-client-header__content">
                <div class="c-client-header__container">
                    <div class="c-client-header__text">
                        <h1 class="c-client-header__title">
                            Bezorging #{{ state.order.reference }}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-page-content">
            <div
                class="c-page-content__container c-page-content__container--spacing"
            >
                <div
                    class="c-delivery"
                    v-if="state.order.boxes && state.order.boxes.length > 0"
                >
                    <!-- @include('frontend.partials.order.tabs.delivery.date') -->
                    <!-- @include('frontend.partials.order.tabs.delivery.date') -->
                    <section class="c-page-content__section e-module-style">
                        <div class="e-section-heading">
                            <div class="e-section-heading__left">
                                <div class="e-section-heading__icon">
                                    <SvgPencil />
                                </div>
                                <h2 class="e-section-heading__name">
                                    Opmerking bezorging
                                </h2>
                            </div>
                        </div>

                        <!-- IF NOT Verschilt per samenstelling -->
                        <div v-if="!state.order.has_varying_deliveries">
                            <resize-textarea
                                ref="myTextarea"
                                :min-height="50"
                                style="padding: 1.5rem; border: none"
                                v-for="(box, index) in state.order.boxes"
                                :key="box.id"
                                v-show="index == 0"
                                xx=" && box.shipping_remarks"
                                v-model="box.shipping_remarks"
                                class="e-form__textarea js-textarea-auto-height"
                                readonly
                                disabled
                                placeholder="Opmerkingen over de bezorging worden hier zichtbaar. "
                            />

                            <!-- <div class="c-page-content__section-button">
                                <button
                                    class="e-button e-button--green mt-10"
                                    @click="
                                        changeDeliverySetting(
                                            'shipping_remarks',
                                            box
                                        )
                                    "
                                >
                                    Opmerking opslaan
                                </button>
                            </div> -->
                        </div>

                        <!-- IF Verschilt per samenstelling -->

                        <div
                            class="c-page-content__sub-sections"
                            v-if="state.order.has_varying_deliveries"
                        >
                            <div
                                class="c-page-content__sub-section"
                                v-for="box in state.order.boxes"
                                :key="box.id"
                                x-show="box.shipping_remarks"
                            >
                                <h2 class="c-page-content__sub-section-title">
                                    <SvgGift /> {{ box.name }}
                                </h2>

                                <resize-textarea
                                    ref="myTextarea"
                                    :min-height="50"
                                    style="padding: 1.5rem; border: none"
                                    class="e-form__textarea mt-10"
                                    v-model="box.shipping_remarks"
                                    readonly
                                    disabled
                                    placeholder="Opmerkingen over de bezorging worden hier zichtbaar. "
                                />

                                <!-- <div class="c-page-content__section-button">
                                    <button
                                        class="e-button e-button--green mt-10"
                                        @click="
                                            changeDeliverySetting(
                                                'shipping_remarks',
                                                box
                                            )
                                        "
                                    >
                                        Opmerking opslaan
                                    </button>
                                </div> -->
                            </div>
                        </div>
                    </section>

                    <section
                        class="c-page-content__section"
                        :class="{
                            'foob-arc-page-content__section--disabled':
                                !state.mayAccess,
                            'NOT-c-page-content__section--disabled':
                                state.order.status &&
                                state.order.status.label &&
                                !state.order.status.label.includes(
                                    'In ontvangst'
                                ) &&
                                !state.order.status.label.includes('Concept'),
                        }"
                    >
                        <Transition name="fade">
                            <delivery-addresses
                                v-if="!state.loading"
                                :order="state.order"
                                auth="Contact"
                            ></delivery-addresses>
                        </Transition>
                    </section>

                    <section
                        class="c-page-content__section e-module-style"
                        :class="{
                            'c-page-content__section--disabled':
                                state.order.status &&
                                state.order.status.label &&
                                !state.order.status.label.includes(
                                    'In ontvangst'
                                ) &&
                                !state.order.status.label.includes('Concept'),
                            'c-page-content__section--disabled':
                                state.order.is_urgent,
                        }"
                    >
                        <div class="e-section-heading">
                            <div class="e-section-heading__left">
                                <h2 class="e-section-heading__name">
                                    Leverdatum
                                </h2>
                            </div>
                        </div>
                        <Transition name="fade">
                            <!-- IF NOT verschilt per samenstelling -->
                            <div class="e-form">
                                <div
                                    class="c-page-content__section"
                                    v-if="!state.order.has_varying_deliveries"
                                >
                                    <div
                                        v-for="(box, index) in state.order
                                            .boxes"
                                        :key="box.id"
                                        v-show="index == 0"
                                    >
                                        <div
                                            class="e-note mt-10"
                                            v-if="
                                                box.delivery_options &&
                                                Object.values(
                                                    box.delivery_options
                                                ).length > 0 &&
                                                !box.delivery_date
                                            "
                                        >
                                            <SvgInfo />
                                            <div class="e-note__text">
                                                <p>
                                                    Selecteer de gewenste
                                                    afleverdatum uit
                                                    onderstaande opties:
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            class="e-form__field-wrap e-form__field-wrap--radios mt-10"
                                            v-if="
                                                box.delivery_options &&
                                                Object.values(
                                                    box.delivery_options
                                                ).length > 0 &&
                                                !box.delivery_date
                                            "
                                        >
                                            <div
                                                class="e-form__multiple-radios delivery_options"
                                            >
                                                <label
                                                    class="e-form__radio-wrap"
                                                    v-for="(
                                                        option, value
                                                    ) in box.delivery_options"
                                                    :key="value"
                                                >
                                                    <input
                                                        class="e-form__radio"
                                                        type="radio"
                                                        :value="option"
                                                        v-model="
                                                            box.delivery_selected_option
                                                        "
                                                        @change="
                                                            changeDeliverySetting(
                                                                'delivery_date',
                                                                box.delivery_selected_option,
                                                                box.hash
                                                            )
                                                        "
                                                    />
                                                    <span
                                                        class="e-form__radio-label"
                                                    ></span>
                                                    <span
                                                        class="e-form__radio-text"
                                                        >{{
                                                            box
                                                                .delivery_options_formatted[
                                                                value
                                                            ]
                                                        }}</span
                                                    >
                                                </label>
                                            </div>
                                        </div>

                                        <div
                                            class="e-form__field-wrap mt-10"
                                            v-show="
                                                box.delivery_date ||
                                                Object.values(
                                                    box.delivery_options
                                                ).length == 0
                                            "
                                        >
                                            <span
                                                class="e-form__label e-form__label--nowrap"
                                                >Definitieve datum
                                                <span v-if="box.delivery_week"
                                                    >| WK
                                                    {{
                                                        box.delivery_week.split(
                                                            "_"
                                                        )[0]
                                                    }}</span
                                                ></span
                                            >
                                            <input
                                                type="date"
                                                class="e-form__input"
                                                :disabled="box.delivery_date"
                                                v-model="box.delivery_date"
                                            />

                                            <button
                                                class="e-button e-button--grey-dark"
                                                @click="
                                                    changeDeliverySetting(
                                                        'delivery_date',
                                                        box.delivery_date,
                                                        box.hash
                                                    )
                                                "
                                            >
                                                Opslaan
                                            </button>
                                            <button
                                                v-show="
                                                    box.delivery_date &&
                                                    state.order.status.label ==
                                                        'In ontvangst'
                                                "
                                                class="e-section-heading__icon e-section-heading__icon--delete"
                                                @click="
                                                    changeDeliverySetting(
                                                        'delivery_date',
                                                        null,
                                                        box.hash
                                                    )
                                                "
                                            >
                                                <SvgDelete />
                                            </button>
                                        </div>
                                        <p
                                            class="mt-10"
                                            v-if="
                                                box.shipping_method ==
                                                    'Op locatie' &&
                                                box.shipping_transporter ==
                                                    'PostNL'
                                            "
                                        >
                                            Let op: De gekozen datum is de datum
                                            waarop wij het pakket afgeven bij
                                            PostNL. Door de drukte bij PostNL
                                            kunnen wij niet garanderen dat je
                                            bestelling op tijd geleverd wordt.
                                            Wij verzenden je bestelling met
                                            PostNL incl track & trace.
                                        </p>
                                    </div>
                                </div>

                                <!-- IF Verschilt per samenstelling -->
                                <div
                                    class="c-page-content__sub-sections"
                                    v-if="state.order.has_varying_deliveries"
                                >
                                    <div
                                        class="c-page-content__sub-section"
                                        v-for="box in state.order.boxes"
                                        :key="box.id"
                                    >
                                        <h2
                                            class="c-page-content__sub-section-title"
                                        >
                                            <SvgGift /> {{ box.name }}
                                        </h2>
                                        <div
                                            class="e-note mt-10"
                                            v-if="
                                                box.delivery_options &&
                                                Object.values(
                                                    box.delivery_options
                                                ).length > 0 &&
                                                !box.delivery_date
                                            "
                                        >
                                            <SvgInfo />
                                            <div class="e-note__text">
                                                <p>
                                                    Selecteer de gewenste
                                                    afleverdatum uit
                                                    onderstaande opties:
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            class="e-form__field-wrap e-form__field-wrap--radios mt-10"
                                            v-if="
                                                box.delivery_options &&
                                                Object.values(
                                                    box.delivery_options
                                                ).length > 0 &&
                                                !box.delivery_date
                                            "
                                        >
                                            <div
                                                class="e-form__multiple-radios delivery_options"
                                            >
                                                <label
                                                    class="e-form__radio-wrap"
                                                    v-for="(
                                                        option, value
                                                    ) in box.delivery_options"
                                                    :key="value"
                                                >
                                                    <input
                                                        class="e-form__radio"
                                                        type="radio"
                                                        :value="option"
                                                        v-model="
                                                            box.delivery_selected_option
                                                        "
                                                        v-show="
                                                            state.order.status
                                                                .label ==
                                                            'In ontvangst'
                                                        "
                                                        @change="
                                                            changeDeliverySetting(
                                                                'delivery_date',
                                                                box.delivery_selected_option,
                                                                box.hash
                                                            )
                                                        "
                                                    />
                                                    <span
                                                        class="e-form__radio-label"
                                                    ></span>
                                                    <span
                                                        class="e-form__radio-text"
                                                        >{{
                                                            box
                                                                .delivery_options_formatted[
                                                                value
                                                            ]
                                                        }}</span
                                                    >
                                                </label>
                                            </div>
                                        </div>

                                        <div
                                            class="e-form__field-wrap mt-10"
                                            v-show="
                                                box.delivery_date ||
                                                Object.values(
                                                    box.delivery_options
                                                ).length == 0
                                            "
                                        >
                                            <span
                                                class="e-form__label e-form__label--nowrap"
                                                >Definitieve datum
                                                <span v-if="box.delivery_week"
                                                    >| WK
                                                    {{
                                                        box.delivery_week.split(
                                                            "_"
                                                        )[0]
                                                    }}</span
                                                >
                                            </span>
                                            <input
                                                type="date"
                                                class="e-form__input"
                                                v-model="box.delivery_date"
                                                :disabled="box.delivery_date"
                                            />
                                            <button
                                                class="e-button e-button--grey-dark"
                                                v-show="
                                                    state.order.status.label ==
                                                    'In ontvangst'
                                                "
                                                @click="
                                                    changeDeliverySetting(
                                                        'delivery_date',
                                                        box.delivery_date,
                                                        box.hash
                                                    )
                                                "
                                            >
                                                Opslaan
                                            </button>
                                            <button
                                                v-show="
                                                    box.delivery_date &&
                                                    state.order.status.label ==
                                                        'In ontvangst'
                                                "
                                                class="e-section-heading__icon e-section-heading__icon--delete"
                                                @click="
                                                    changeDeliverySetting(
                                                        'delivery_date',
                                                        null,
                                                        box.hash
                                                    )
                                                "
                                            >
                                                <SvgDelete />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </section>

                    <section class="c-page-content__section e-module-style">
                        <div class="e-section-heading">
                            <div class="e-section-heading__left">
                                <h2 class="e-section-heading__name">
                                    Type bezorging
                                </h2>
                            </div>
                            <!--
                             <div class="e-section-heading__right">
                                <label class="e-form__checkbox-wrap">
                                    <input
                                        class="e-form__checkbox"
                                        type="checkbox"
                                        v-model="
                                            state.order.has_varying_deliveries
                                        "
                                        @change="switchVaryingDelivery()"
                                        value="true"
                                    />
                                    <span class="e-form__checkbox-label"></span>
                                    <span class="e-form__checkbox-text"
                                        >Verschilt per samenstelling</span
                                    >
                                </label>
                            </div> 
                            -->
                        </div>

                        <!-- IF NOT Verschilt per samenstelling -->

                        <div
                            class="e-form__field-wrap e-form__field-wrap--radios"
                            v-if="!state.order.has_varying_deliveries"
                        >
                            <div
                                class="e-form__multiple-radios"
                                v-for="(box, index) in state.order.boxes"
                                :key="box.id"
                                v-show="index == 0"
                            >
                                <label
                                    class="e-form__radio-wrap"
                                    v-for="(option, value) in enums.options[
                                        'box-shipping-methods'
                                    ]"
                                    :class="{
                                        disabled: Boolean(
                                            state.order.status.label !=
                                                'In ontvangst'
                                        ),
                                    }"
                                    :key="value"
                                >
                                    <input
                                        class="e-form__radio"
                                        type="radio"
                                        x-name="'delivery-type' + box.id"
                                        v-model="box.shipping_method"
                                        :value="option"
                                        :disabled="
                                            Boolean(
                                                state.order.status.label !=
                                                    'In ontvangst'
                                            )
                                        "
                                        @change="
                                            changeDeliverySetting(
                                                'shipping_method',
                                                box.shipping_method,
                                                box.hash
                                            )
                                        "
                                    />
                                    <span class="e-form__radio-label"></span>
                                    <span class="e-form__radio-text">{{
                                        option
                                    }}</span>
                                </label>
                            </div>
                        </div>

                        <!-- IF Verschilt per samenstelling -->

                        <div
                            class="c-page-content__sub-sections"
                            v-if="state.order.has_varying_deliveries"
                        >
                            <div
                                class="c-page-content__sub-section"
                                v-for="box in state.order.boxes"
                                :key="box.id"
                            >
                                <h2 class="c-page-content__sub-section-title">
                                    <SvgGift /> {{ box.name }}
                                </h2>
                                <div
                                    class="e-form__field-wrap e-form__field-wrap--radios mt-10"
                                >
                                    <div class="e-form__multiple-radios">
                                        <label
                                            class="e-form__radio-wrap"
                                            v-for="(option, value) in enums
                                                .options[
                                                'box-shipping-methods'
                                            ]"
                                            :class="{
                                                disabled: Boolean(
                                                    state.order.status.label !=
                                                        'In ontvangst'
                                                ),
                                            }"
                                            :key="value"
                                        >
                                            <input
                                                class="e-form__radio"
                                                type="radio"
                                                x-name="'delivery-type' + box.id"
                                                v-model="box.shipping_method"
                                                :value="option"
                                                :disabled="
                                                    Boolean(
                                                        state.order.status
                                                            .label !=
                                                            'In ontvangst'
                                                    )
                                                "
                                                @change="
                                                    changeDeliverySetting(
                                                        'shipping_method',
                                                        box.shipping_method,
                                                        box.hash
                                                    )
                                                "
                                            />
                                            <span
                                                class="e-form__radio-label"
                                            ></span>
                                            <span class="e-form__radio-text">{{
                                                option
                                            }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <!-- @include('frontend.partials.order.tabs.delivery.company') -->
                    <section
                        class="c-page-content__section e-module-style"
                        style="display: none"
                    >
                        <div class="e-section-heading">
                            <div class="e-section-heading__left">
                                <h2 class="e-section-heading__name">
                                    Transporteur
                                </h2>
                            </div>
                        </div>

                        <!-- IF NOT Verschilt per samenstelling -->

                        <div
                            class="e-form__field-wrap e-form__field-wrap--radios e-form__field-wrap--radios-list"
                            v-if="!state.order.has_varying_deliveries"
                        >
                            <div
                                class="e-form__multiple-radios"
                                v-for="(box, index) in state.order.boxes"
                                :key="box.id"
                                v-show="index == 0"
                            >
                                <label
                                    class="e-form__radio-wrap"
                                    :class="{
                                        disabled: true,
                                    }"
                                    v-for="(option, value) in enums.options[
                                        'box-transporters'
                                    ]"
                                    :key="value"
                                >
                                    <input
                                        class="e-form__radio"
                                        type="radio"
                                        :name="'delivery-type' + box.id"
                                        v-model="box.shipping_transporter"
                                        disabled="true"
                                        :value="option"
                                    />
                                    <span class="e-form__radio-label"></span>
                                    <span class="e-form__radio-text">{{
                                        option
                                    }}</span>
                                </label>
                            </div>
                        </div>

                        <!-- IF Verschilt per samenstelling -->

                        <div
                            class="c-page-content__sub-sections"
                            v-if="state.order.has_varying_deliveries"
                        >
                            <div
                                class="c-page-content__sub-section"
                                v-for="box in state.order.boxes"
                                :key="box.id"
                                v-show="box.shipping_method != null"
                            >
                                <h2 class="c-page-content__sub-section-title">
                                    <SvgGift /> {{ box.name }}
                                </h2>
                                <div
                                    class="e-form__field-wrap e-form__field-wrap--radios e-form__field-wrap--radios-list mt-10"
                                >
                                    <div class="e-form__multiple-radios">
                                        <label
                                            class="e-form__radio-wrap"
                                            :class="{
                                                disabled: true,
                                            }"
                                            v-for="(option, value) in enums
                                                .options['box-transporters']"
                                            :key="value"
                                        >
                                            <input
                                                class="e-form__radio"
                                                type="radio"
                                                :name="'delivery-type' + box.id"
                                                v-model="
                                                    box.shipping_transporter
                                                "
                                                disabled="true"
                                                :value="option"
                                            />
                                            <span
                                                class="e-form__radio-label"
                                            ></span>
                                            <span class="e-form__radio-text">{{
                                                option
                                            }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- @include('frontend.partials.order.tabs.delivery.address') -->

                    <!-- @include('frontend.partials.order.tabs.delivery.note') -->
                </div>
            </div>
        </div>
    </article>
</template>

<script setup>
import SvgDelete from "@/svg/delete.svg";
import SvgGift from "@/svg/gift.svg";
import SvgInfo from "@/svg/info.svg";
import SvgPencil from "@/svg/pencil.svg";

import { useBoxStore } from "@/store/client/box";
import { inject, onMounted, reactive } from "vue";

import { useAuthClientStore } from "@/store/authClient";
import { useEnumStore } from "@/store/client/enum";
import { useOrderStore } from "@/store/client/order";
import SecureLS from "secure-ls";
import Swal from "sweetalert2";
import { useRoute } from "vue-router";

const order = useOrderStore();

const user = useAuthClientStore();

const Modals = inject("Modals");
const _box = useBoxStore();

order.delivery(user.order());
// order.invoice(useRoute().params.hash);

const route = useRoute();
const enums = useEnumStore();

enums.fill("box-shipping-methods");
enums.fill("box-transporters");

const state = reactive({
    invoice_status: null,
    loading: true,
    delivery: null,
    invoice: {
        data: [],
        totals: null,
    },
    order: {
        hash: null,
        has_varying_deliveries: false,
        contact: {
            address: {},
        },
        followers: {},
        boxes: null,
    },
    mayAccess: false,
    pin: null,
});

onMounted(() => {
    Eventing.listen("fillDelivery", (data) => {
        state.order = data.data;
        state.delivery = data.delivery;
        state.loading = false;
        if (state.order.boxes && state.order.boxes.length == 0) {
            Eventing.fire(
                "toasting",
                "Er zijn nog geen samenstellingen bekend",
                "info"
            );
        }
    });
    Eventing.listen("reloadDelivery", () => {
        order.delivery(user.order());
    });
    Eventing.listen("fillOrder", (data) => {
        state.order = data.data;
        setPin(state.order.pin);
        state.loading = false;
    });
    Eventing.listen("reloadDeliveryPage", () => {
        // checkIfMayAccess();
    });
    // checkIfMayAccess();
});

function setPin(code) {
    const t = new Date();
    let w = parseInt(t.getDay()) + 1;
    let d = parseInt(t.getDate());

    let pin = parseInt(code) / parseInt(w) / parseInt(d);
    state.pin = pin;

    const ls = new SecureLS();
    ls.set("pin", pin);
}

// function mayAccessToday() {
//     const t = new Date();
//     let w = parseInt(t.getDay()) + 1;
//     let d = parseInt(t.getDate());

//     const ls = new SecureLS();
//     ls.set("mayAccess", w + d);
// }

// function checkAccessToday() {
//     const t = new Date();
//     let w = parseInt(t.getDay()) + 1;
//     let d = parseInt(t.getDate());

//     const ls = new SecureLS();
//     if (ls.get("mayAccess") == w + d) {
//         state.mayAccess = true;
//     }
// }

// async function checkIfMayAccess() {
//     checkAccessToday();

//     if (state.mayAccess == false) {
//         Swal.fire({
//             title: "Extra verificatie stap",
//             text: "Om er zeker van te zijn dat jij de contactpersoon is die momenteel is ingelogd willen wij een extra vertificatie stap uitvoeren. Per e-mail zul je een code ontvangen welk je hierna in kunt vullen. Wanneer deze stap succesvol is afgerond wordt het mogelijk om alles met betrekking tot de bezorging te regelen.",
//             confirmButtonText: "Verstuur e-mail met code",
//             showCloseButton: true,
//             showCancelButton: true,
//             cancelButtonText: "Annuleren",
//             icon: "warning",
//         }).then(async (result) => {
//             if (result.isConfirmed) {
//                 Eventing.fire(
//                     "toasting",
//                     "Check je mailbox voor de toegangscode om door te gaan"
//                 );
//                 let result = await order.update(user.order(), {
//                     sendPin: true,
//                 });

//                 if (result) {
//                     setPin(result.data.pin);

//                     const { value: check } = await Swal.fire({
//                         title: "Voer code in",
//                         text: "De ontvangen code is vandaag geldig en meerdere keren te gebruiken",
//                         confirmButtonText: "Verkrijg toegang",
//                         showCloseButton: true,
//                         showCancelButton: true,
//                         cancelButtonText: "Annuleren",
//                         icon: "question",
//                         input: "text",
//                         inputValidator: (value) => {
//                             return new Promise((resolve) => {
//                                 if (parseInt(value) === parseInt(state.pin)) {
//                                     resolve();
//                                     state.mayAccess = true;
//                                     order.delivery(user.order());
//                                     Eventing.fire(
//                                         "toasting",
//                                         "Je kunt de bezorging nu beheren!"
//                                     );
//                                     mayAccessToday();
//                                 } else {
//                                     resolve(
//                                         "Dit is niet de juiste toegangscode"
//                                     );
//                                 }
//                             });
//                         },
//                     });
//                 }
//             }
//         });
//     }
// }

// function changeDeliverySetting(field, _value, hash) {
//     state.loading = true;
//     _box.updateBoxDelivery(user.order(), {
//         field: field,
//         value: _value,
//         box: hash,
//     });
// }
function changeDeliverySetting(field, _value, hash) {
    state.loading = true;

    if (_value == null) {
        if (confirm("Zeker weten?")) {
            return _box.updateBoxDelivery(user.order(), {
                field: field,
                value: _value,
                box: hash,
            });
        }
        return;
    }
    Swal.fire({
        text:
            'Zeker weten dat je "' +
            _value +
            '" wilt kiezen' +
            translateField(field),
        confirmButtonText: "Ja",
        showCloseButton: true,
        icon: "info",
    }).then((result) => {
        if (result.isConfirmed) {
            _box.updateBoxDelivery(user.order(), {
                field: field,
                value: _value,
                box: hash,
            });
        }
    });
}

function translateField(field) {
    switch (field) {
        case "shipping_method":
            return " voor type bezorging?";
        case "delivery_date":
            return " als definitieve leverdatum?";
        case "shipping_transporter":
            return " als transporteur?";
        case "shipping_remarks":
            return " als opmerking voor bezorging?";
        default:
            return "?";
    }
}

function switchVaryingDelivery() {
    state.loading = true;
    order.switchVaryingDelivery(
        state.order.hash,
        state.order.has_varying_deliveries
    );
}
</script>

<style>
.swal2-cancel.swal2-styled {
    opacity: 0.75 !important;
}
</style>
